import * as PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

import ChevronLeftIcon from "../@atoms/chevron-left-icon";
import ChevronRightIcon from "../@atoms/chevron-right-icon";

class PaginationWithResultsCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemCount: this.props.itemCount,
      perPage: 0,
      currentPage: this.props.page + 1,
      totalPages: 0,
      pagesFit: 14,
      width: 800,
      pages: [],
    };

    this._handleWindowResize = this._handleWindowResize.bind(this);
  }

  async pageDown() {
    if (this.state.currentPage > 1) {
      if (this.props.onPageChange) {
        await this.setState({
          currentPage: this.state.currentPage - 1,
        });
        this.props.onPageChange(this.state.currentPage - 1);
        this.getPages();
      }
    }
  }

  async pageUp() {
    if (this.state.currentPage < this.state.totalPages) {
      if (this.props.onPageChange) {
        await this.setState({
          currentPage: this.state.currentPage + 1,
        });
        this.props.onPageChange(this.state.currentPage - 1);
        this.getPages();
      }
    }
  }

  async pageNumberClicked(currentPage) {
    await this.setState({
      currentPage: currentPage,
    });
    this.getPages();
    this.props.onPageChange(currentPage - 1);
  }

  getPagesFit() {
    let numberSpace = ReactDOM.findDOMNode(this._containerTarget).offsetWidth - 87;
    let pagesFit = Math.floor(numberSpace / 42);
    this.setState({
      pagesFit: pagesFit,
    });
  }

  getPages() {
    let pages = [];
    let firstPageNumber =
      this.state.currentPage - Math.floor((this.state.pagesFit - 1) / 2) <= 1 ||
      this.state.totalPages <= this.state.pagesFit
        ? 1
        : this.state.currentPage - Math.floor((this.state.pagesFit - 1) / 2);
    if (firstPageNumber > this.state.totalPages - (this.state.pagesFit - 1)) {
      firstPageNumber = this.state.totalPages - (this.state.pagesFit - 1);
    }
    if (firstPageNumber < 1) {
      firstPageNumber = 1;
    }
    for (
      let i = 0;
      i < (this.state.totalPages < this.state.pagesFit ? this.state.totalPages : this.state.pagesFit);
      i++
    ) {
      let currentPage = firstPageNumber + i;

      let content = "";

      if (i === 0 && firstPageNumber > 1) {
        content = "...";
      } else if (this.state.pagesFit === i + 1 && this.state.totalPages !== currentPage) {
        content = "...";
      } else {
        content = currentPage;
      }

      pages.push(
        <React.Fragment>
          <li
            className={"page-number-item" + (currentPage !== this.state.currentPage ? " click" : "")}
            key={currentPage}
            onClick={() => this.pageNumberClicked(currentPage)}>
            <span
              className={"page-number-content" + (currentPage === this.state.currentPage ? " active" : "")}>
              {content}
            </span>
          </li>
          <style jsx>{`
            .page-number-item {
              width: 42px;
              height: 42px;
              box-sizing: border-box;
              display: inline-block;
              margin: 0;
              list-style: none;
              text-align: center;
              cursor: auto;
            }

            .page-number-item.click {
              cursor: pointer;
            }

            .page-number-content {
              width: 42px;
              height: 42px;
              line-height: 42px;
              font-size: 26px;
              color: #bfbfbf;
            }

            .page-number-content.active {
              color: #00374d;
            }

            .page-number-content > a {
              color: #bfbfbf;
            }

            .page-number-content.active > a {
              color: #00374d;
            }
          `}</style>
        </React.Fragment>
      );
    }
    this.setState({
      pages: pages,
    });
  }

  async componentDidMount() {
    window.addEventListener("resize", this._handleWindowResize);
    await this.setState({
      width: ReactDOM.findDOMNode(this._containerTarget).offsetWidth,
      totalPages: Math.ceil(this.props.itemCount / this.props.perPage),
      itemCount: this.props.itemCount,
      perPage: this.props.perPage,
    });
    await this.getPagesFit();
    await this.getPages();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._handleWindowResize);
  }

  async _handleWindowResize() {
    await this.setState({
      width: ReactDOM.findDOMNode(this._containerTarget).offsetWidth,
    });
    await this.getPagesFit();
    this.getPages();
  }

  render() {
    return (
      <React.Fragment>
        <div className="pagination-container">
          <div className="list-box">
            <ul
              className="page-number-container"
              ref={(node) => {
                if (node !== null) {
                  this._containerTarget = node;
                }
              }}>
              <li
                className={"page-number-item" + (this.state.currentPage > 1 ? " click" : "")}
                onClick={this.pageDown.bind(this)}
                style={{
                  marginRight: Math.floor((this.state.width - (87 + 42 * this.state.pagesFit)) * 0.5),
                }}>
                <ChevronLeftIcon color="#bfbfbf" />
              </li>
              {this.state.pages}
              <li
                className={
                  "page-number-item" + (this.state.currentPage < this.state.totalPages ? " click" : "")
                }
                onClick={this.pageUp.bind(this)}
                style={{
                  marginLeft: Math.floor((this.state.width - (87 + 42 * this.state.pagesFit)) * 0.5),
                }}>
                <ChevronRightIcon color="#bfbfbf" />
              </li>
            </ul>
            <div className="page-info">
              <span style={{ cursor: "default" }}>
                {this.state.currentPage * this.state.perPage -
                  (this.state.perPage - 1) +
                  "-" +
                  (this.state.currentPage * this.state.perPage > this.state.itemCount
                    ? this.state.itemCount
                    : this.state.currentPage * this.state.perPage) +
                  " of " +
                  this.state.itemCount +
                  " results"}
              </span>
            </div>
          </div>
        </div>
        <style jsx>{`
          .pagination-container {
            padding: 0;
            margin: 0;
            position: relative;
            box-sizing: border-box;
            width: 100%;
          }

          .list-box {
            padding: 26px;
            margin: 0;
            position: relative;
            box-sizing: border-box;
          }

          .page-number-container {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            position: relative;
            vertical-align: top;
            display: inline-block;
            height: 42px;
            overflow: hidden;
            width: 100%;
            text-align: center;
          }

          .page-number-item {
            width: 42px;
            height: 42px;
            box-sizing: border-box;
            display: inline-block;
            margin: 0;
            list-style: none;
            text-align: center;
            cursor: auto;
          }

          .page-number-item.click {
            cursor: pointer;
          }

          .page-number-content {
            width: 42px;
            height: 42px;
            line-height: 42px;
            font-size: 26px;
            color: #bfbfbf;
          }

          .page-number-content.active {
            color: #00374d;
          }

          .page-number-content > a {
            color: #bfbfbf;
          }

          .page-number-content.active > a {
            color: #00374d;
          }

          .page-info {
            width: 100%;
            padding-top: 26px;
            display: block;
            text-align: center;
            font-size: 26px;
            color: #bfbfbf;
          }

          @media screen and (min-width: 1025px) {
            .page-number-container {
              width: calc(100% - 200px);
            }

            .page-info {
              width: 200px;
              height: 42px;
              padding: 0;
              line-height: 42px;
              display: inline-block;
              text-align: right;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

PaginationWithResultsCount.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default PaginationWithResultsCount;
